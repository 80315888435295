// Note:
// _menu: nếu là các menu
// _act: nếu là các action của button, ...

export const GlobalPolcies = {
  khenThuongKyLuat: {
    left_menu: {
      trang_chu: '',
      khen_thuong_ky_luat: '1515', //web: menu left - Khen thưởng kỷ luật v2 - Quản Lý
      khen_thuong_ky_luat_menu: {
        trang_chu: '1525', //web: menu left - Khen thưởng kỷ luật v2 - Quản lý - Trang chủ
        quan_ly_cap_khoa: '1510', //web: menu left - Khen thưởng kỷ luật v2 - Quản lý - Quản lý cấp khoa
        quan_ly_cap_khoa_menu: {
          quan_ly_ky_luat: '1526', //web: menu left - Khen thưởng kỷ luật v2 - Quản lý - Quản lý cấp khoa - Quản lý kỷ luật
          quan_ly_khen_thuong: '1527', //web: menu left - Khen thưởng kỷ luật v2 - Quản lý - Quản lý cấp khoa - Quản lý khen thưởng
        },
        quan_ly_cap_truong: '1511', //web: menu left - Khen thưởng kỷ luật v2 - Quản lý - Quản lý cấp trường
        quan_ly_cap_truong_menu: {
          quan_ly_ky_luat: '1528', //web: menu left - Khen thưởng kỷ luật v2 - Quản lý - Quản lý cấp trường - Quản lý kỷ luật
          quan_ly_khen_thuong: '1529', //web: menu left - Khen thưởng kỷ luật v2 - Quản lý - Quản lý cấp trường - Quản lý khen thưởng
        },
        quan_ly_phan_quyen: '1512', //web: menu left - Khen thưởng kỷ luật v2 - Quản lý - Quản lý phân quyền
        quan_ly_thong_ke: '1535', //web: menu left - Khen thưởng kỷ luật v2 - Quản lý - Quản lý thống kê
        huong_dan_su_dung: '1513', //web: menu left - Khen thưởng kỷ luật v2 - Quản lý - Hướng dẫn sử dụng
        import_du_lieu: '1520', //web: menu left - Khen thưởng kỷ luật v2 - Quản lý - Import dữ liệu
        cai_dat: '1536', //web: menu left - Khen thưởng kỷ luật v2 - Quản lý - Cài đặt
        cai_dat_menu: {
          tai_khoan: '1537', //web: menu left - Khen thưởng kỷ luật v2 - Quản lý - Cài đặt - Tài khoản
          don_vi: '1538', //web: menu left - Khen thưởng kỷ luật v2 - Quản lý - Cài đặt - Đơn vị
        },
        import_excel: '1646', //web: menu left - Khen thưởng kỷ luật v2 - Quản lý - Import Excel
        import_excel_menu: {
          import_excel_loai_1: '1647', //web: menu left - Khen thưởng kỷ luật v2 - Quản lý - Import Excel - Import Excel loại 1
          import_excel_loai_1_menu: {
            danh_sach: '1648', //web: menu left - Khen thưởng kỷ luật v2 - Quản lý - Import Excel - Import Excel loại 1 - Danh sách
            thiet_lap_mau: '1649', //web: menu left - Khen thưởng kỷ luật v2 - Quản lý - Import Excel - Import Excel loại 1 - Thiết lập mẫu
          }
        }
      },
      khen_thuong_ky_luat_sinh_vien: '1514', //web: menu left - Khen thưởng kỷ luật v2 - Sinh viên
    },
    chi_tiet_kltkl: {
      tac_vu_cap_truong_act: '1516', //web: action - Khen thưởng kỷ luật v2 - Quản lý - Quản lý cấp trường - Tác vụ thông tin chi tiết
      tac_vu_cap_truong_lanh_dao_act: '1556', //web: action - Khen thưởng kỷ luật v2 - Quản lý - Quản lý cấp trường - Tác vụ thông tin chi tiết - Lãnh đạo
      tac_vu_cap_truong_nhan_vien_act: '1555', //web: action - Khen thưởng kỷ luật v2 - Quản lý - Quản lý cấp trường - Tác vụ thông tin chi tiết - Nhân viên
      tac_vu_cap_khoa_act: '1517', //web: action - Khen thưởng kỷ luật v2 - Quản lý - Quản lý cấp khoa - Tác vụ thông tin chi tiết
    },
    danh_sach_quan_ly: {
      cap_truong: {
        ky_luat: {
          tac_vu_excel_act: '1530', //web: action - Khen thưởng kỷ luật v2 - Quản lý - Quản lý cấp trường - Quản lý kỷ luật - Tác vụ excel
          tac_vu_khac_act: '1531', //web: action - Khen thưởng kỷ luật v2 - Quản lý - Quản lý cấp trường - Quản lý kỷ luật - Tác vụ khác
          tac_vu_import_excel_act: '1560', //web: action - Khen thưởng kỷ luật v2 - Quản lý - Quản lý cấp trường - Quản lý kỷ luật - Tác vụ excel - Import
          tac_vu_export_excel_act: '1561', //web: action - Khen thưởng kỷ luật v2 - Quản lý - Quản lý cấp trường - Quản lý kỷ luật - Tác vụ excel - Export
          tac_vu_them_du_lieu_act: '1557', //web: action - Khen thưởng kỷ luật v2 - Quản lý - Quản lý cấp trường - Quản lý kỷ luật - Tác vụ thêm dữ liệu
          tac_vu_khac_lanh_dao_act: '1558', //web: action - Khen thưởng kỷ luật v2 - Quản lý - Quản lý cấp trường - Quản lý kỷ luật - Tác vụ khác - Lãnh đạo
          tac_vu_khac_nhan_vien_act: '1559', //web: action - Khen thưởng kỷ luật v2 - Quản lý - Quản lý cấp trường - Quản lý kỷ luật - Tác vụ khác - Nhân viên
        },
        khen_thuong: {
          tac_vu_excel_act: '1532', //web: action - Khen thưởng kỷ luật v2 - Quản lý - Quản lý cấp trường - Quản lý khen thưởng - Tác vụ excel
          tac_vu_khac_act: '1533', //web: action - Khen thưởng kỷ luật v2 - Quản lý - Quản lý cấp trường - Quản lý khen thưởng - Tác vụ khác
        },
      },
      cap_khoa: {
        ky_luat: {
          tac_vu_khac_act: '1534', //web: action - Khen thưởng kỷ luật v2 - Quản lý - Quản lý cấp khoa - Quản lý kỷ luật - Tác vụ khác
          tac_vu_excel_act: '1644', //web: action - Khen thưởng kỷ luật v2 - Quản lý - Quản lý cấp khoa - Quản lý kỷ luật - Tác vụ excel
          tac_vu_export_excel_act: '1645', //web: action - Khen thưởng kỷ luật v2 - Quản lý - Quản lý cấp khoa - Quản lý kỷ luật - Tác vụ excel - Export
        },
      },
    },
    dashboard_act: {
      sumary_act: {
        khen_thuong_ky_luat: '1569',//web: action - Khen thưởng kỷ luật v2 - Quản lý - Trang chủ  - Khen thưởng kỷ luật
        khen_thuong_ky_luat_act: {
          bieu_do_bien_dong_ky_luat: '1521', //web: action - Khen thưởng kỷ luật v2 - Quản lý - Trang chủ  - Biểu đồ biến động kỷ luật
          bieu_do_bien_dong_khen_thuong: '1522', //web: action - Khen thưởng kỷ luật v2 - Quản lý - Trang chủ  - Biểu đồ biến động khen thưởng
          bieu_do_ty_le_ky_luat: '1523', //web: action - Khen thưởng kỷ luật v2 - Quản lý - Trang chủ  - Biểu đồ tỷ lệ kỷ luật
          bieu_do_ty_le_khen_thuong: '1524', //web: action - Khen thưởng kỷ luật v2 - Quản lý - Trang chủ  - Biểu đồ tỷ lệ khen thưởng
          cap_truong_chart: '1540', //web: action - Khen thưởng kỷ luật v2 - Quản lý - Trang chủ  - Biểu đồ cấp trường
          cap_khoa_chart: '1541', //web: action - Khen thưởng kỷ luật v2 - Quản lý - Trang chủ  - Biểu đồ cấp khoa
          cap_truong: '1650', //web: action - Khen thưởng kỷ luật v2 - Quản lý - Quản lý cấp trường - Summary
          cap_khoa: '1651', //web: action - Khen thưởng kỷ luật v2 - Quản lý - Quản lý cấp khoa - Summary
        }
      },
      link_act: {
        note_ttit: '243',
        lien_ket_nhanh_act: {
          bccn: '175',
          tudien_cb: '278',
          hsns: '177',
          thu_lao_gd: '183',
          quyet_toan_huu_co: '184',
          ho_so_xet_tuyen_hoc_ba: '181',
          test_thu_lao_co_huu: '540'
        }
      }
    },
  },
};

