<div class="dropdown-multilevel dropdown-multilevel-left">
  <div class="dropdown-item" transloco="layouts.appUserMenu.language.label"> </div>

  <div class="dropdown-menu">
    @for(language of languages; track language.name) {
       <a (click)="onChangeLanguage(language.id)" class="dropdown-item"
       [class.active]="currentLanguage === language.id"
       [class.disabled]="language.id !=='vi' && language.id !=='es'"
       data-action="lang"
       data-lang="language.code">
       <span  *transloco="let translated;">
      {{ translated(language.name)}}
      </span>
    </a>
    }
  </div>
</div>
